import { useNavigate } from "@tanstack/react-router";
import { apiDatetimeToDate } from "@warrenio/api-spec/conversion";
import type { BillingAccount, VirtualMachineStorageReplica, VmInterface } from "@warrenio/api-spec/spec.oats.gen";
import { filterFalse } from "@warrenio/utils/collections/filterFalse";
import { first, sortBy } from "remeda";
import { link } from "../../components/Action.tsx";
import { ClipBoardTooltip } from "../../components/ClipBoardTooltip.tsx";
import { ContentPane } from "../../components/ContentPane";
import { NavigateAfterDelete } from "../../components/NavigateAfterDelete.tsx";
import { ResourceWithIcon } from "../../components/ResourceWithIcon.tsx";
import { Separator } from "../../components/Separator.tsx";
import { Spacer } from "../../components/Spacer.tsx";
import { ViewTitle } from "../../components/Title.tsx";
import { type WTabItem, WTabs } from "../../components/WTabs.tsx";
import { InfoTooltip, WTooltip } from "../../components/WTooltip.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { WModalButton, WToolButton } from "../../components/button/WToolButton.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { CurrencyMonthly } from "../../components/l10n/Currency.tsx";
import { LongDate } from "../../components/l10n/DateFormat.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { DetailsHolder, DetailsTable } from "../../components/table/DetailsTable.tsx";
import { DetailsTableBillingAccount } from "../../components/table/DetailsTableBillingAccount.tsx";
import { DetailsTableIpAddress } from "../../components/table/DetailsTableIpAddress.tsx";
import { MonthlyCostRow } from "../../components/table/DetailsTableMonthlyCost.tsx";
import { DetailsTableName } from "../../components/table/DetailsTableName.tsx";
import { DetailsTableRow } from "../../components/table/DetailsTableRow.tsx";
import { WTable, WTableBody } from "../../components/table/WTable.tsx";
import { useConfig } from "../../config.ts";
import { useDeletableResource } from "../../utils/query/useDeletableResource.tsx";
import { useQueryResultAtom, useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { getResourceById } from "../api/resourceTypeException.ts";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { LocationDetail } from "../location/LocationDetail.tsx";
import { VpcRow } from "../network/VpcRow.tsx";
import * as ipQuery from "../network/ipAddress/apiOperations.ts";
import type { AssignedIpAddress } from "../network/ipAddress/assignable.ts";
import type { VpcWithType } from "../network/vpc/apiOperations.ts";
import { vpcQueryAtom } from "../network/vpc/apiOperations.ts";
import { allHostPoolsQueryAtom } from "../pools/query.ts";
import { AddSubInterfaceButton } from "./AddSubInterfaceModal.tsx";
import { DiskAddOrResizeModal } from "./DiskAddOrResizeModal.tsx";
import { DiskDeleteModal } from "./DiskDeleteModal.tsx";
import { MetricsGrid } from "./MetricsGrid.tsx";
import { SubInterfaceDeleteModal } from "./SubInterfaceDeleteModal.tsx";
import { SubInterfaceModal } from "./SubInterfaceModal.tsx";
import { VmDeleteModal } from "./VmDeleteModal.tsx";
import { VmDeleteReplicaModal } from "./VmDeleteReplicaModal.tsx";
import { VmEstimatedReplicaPriceModal } from "./VmEstimatedReplicaPriceModal.tsx";
import { VmResizeInfoButton } from "./VmResizeModal.tsx";
import { VmRestoreFromReplicaModal } from "./VmRestoreFromReplicaModal.tsx";
import { VmStatusBadge } from "./VmStatusBadge.tsx";
import { VmToolbar } from "./VmToolbar.tsx";
import { assignedVmsQueryAtom, type VirtualMachineWithAssigned } from "./joinAssignedQuery.ts";
import { OsInfo } from "./os/OsInfo.tsx";
import { extractOsFields } from "./os/os.ts";
import { getReplicasByType } from "./vmEntityUtils.ts";
import { allInterfacesQueryAtom } from "./vmInterfacesQuery.ts";
import {
    changeVmBillingAccountMutation,
    createSnapshotMutation,
    deleteVmMutation,
    enableDisableBackupMutation,
    modifyVmMutation,
    type VirtualMachineLoc,
} from "./vmQuery";

export function VmView({ vmId, location }: { vmId: string; location: string }) {
    //#region Hooks

    const { privateNetworksEnabled } = useConfig();

    const vms = useSuspenseQueryAtom(assignedVmsQueryAtom(location));
    // Progressive load VPC tab
    const { data: vpcs } = useQueryResultAtom(vpcQueryAtom(location));

    const deleteVmMutation = useDeleteVmMutation();
    const vm = useDeletableResource(() => getResourceById(vms, vmId, "virtual_machine"), deleteVmMutation);
    //#endregion

    if (vm === undefined) {
        return <NavigateAfterDelete />;
    }

    const { uuid } = vm;

    const vpc = vpcs ? [...vpcs.values()].find((item: VpcWithType) => item.vm_uuids.includes(uuid)) : undefined;
    const hasNetworkTab = privateNetworksEnabled && !!vpc;

    const tabs = filterFalse<WTabItem>([
        { id: "metrics", title: "Metrics", content: <MetricsContent vm={vm} /> },
        { id: "disks", title: "Disks", content: <DisksContent vm={vm} /> },
        hasNetworkTab &&
            !!vpc && {
                id: "networking",
                title: "Networking",
                content: <NetworkingContent vm={vm} />,
            },
        {
            id: "backups",
            title: "Backups",
            content: <BackupsContent vm={vm} />,
        },
        {
            id: "snapshots",
            title: "Snapshots",
            content: <SnapshotsContent vm={vm} vpc={vpc} />,
        },
    ]);

    return (
        <>
            <VmToolbar vm={vm} deleteModal={<VmDeleteModal {...{ vm, deleteVmMutation }} />} />
            <VmDetails vm={vm} vpc={vpc} />
            <Separator />
            <WTabs autoSuspense allTab items={tabs} />
        </>
    );
}

function useDeleteVmMutation() {
    return useStandardMutation(deleteVmMutation);
}

export type DeleteVmMutation = ReturnType<typeof useDeleteVmMutation>;

function VmDetails({ vm, vpc }: { vm: VirtualMachineWithAssigned; vpc: VpcWithType | undefined }) {
    //#region Hooks
    const pools = useSuspenseQueryAtom(allHostPoolsQueryAtom);

    const modifyMutation = useStandardMutation(modifyVmMutation);
    const changeBillingAccountMutation = useStandardMutation(changeVmBillingAccountMutation);
    //#endregion

    const {
        $type,
        billing_account,
        created_at,
        designated_pool_uuid,
        location,
        mac,
        name,
        private_ipv4,
        public_ipv6,
        status,
        username,
        uuid,
        price,
    } = vm;

    const pool = designated_pool_uuid ? pools.get(designated_pool_uuid) : undefined;

    async function onRename(newName: string) {
        newName = newName.trim();
        if (newName && vm.name !== newName) {
            newName = newName.trim();
            await modifyMutation.mutateAsync({
                body: { name: newName, uuid },
                location,
            });
        }
    }

    async function changeBillingAccount(billing_account: BillingAccount) {
        await changeBillingAccountMutation.mutateAsync({ location, uuid, billing_account_id: billing_account.id });
    }

    return (
        <ContentPane>
            <ViewTitle title={name} />

            <DetailsHolder>
                <DetailsTable>
                    <DetailsTableName value={name} isRequired onChange={async (name) => await onRename(name)} />

                    <DetailsTableRow title="Type:">
                        <ResourceWithIcon type={$type} />
                    </DetailsTableRow>

                    <DetailsTableRow title="OS:">
                        <OsInfo obj={extractOsFields(vm)} />
                    </DetailsTableRow>

                    <DetailsTableRow title="Status:">
                        <VmStatusBadge value={status} />
                    </DetailsTableRow>

                    {public_ipv6 != null && (
                        <DetailsTableRow title="Public IPv6:">
                            <ClipBoardTooltip isHtml>{public_ipv6}</ClipBoardTooltip>
                        </DetailsTableRow>
                    )}

                    <VmPublicIp item={vm} />

                    <DetailsTableRow title="Private IPv4:">
                        <ClipBoardTooltip isHtml>{private_ipv4}</ClipBoardTooltip>
                    </DetailsTableRow>

                    <VpcRow vpc={vpc} />

                    <DetailsTableRow title="Size:">
                        <VmResizeInfoButton vm={vm} />
                    </DetailsTableRow>
                </DetailsTable>

                <Spacer />

                <DetailsTable>
                    <DetailsTableRow title="UUID:">
                        <ClipBoardTooltip>{uuid}</ClipBoardTooltip>
                    </DetailsTableRow>

                    <DetailsTableRow title="Username:">{username}</DetailsTableRow>

                    <DetailsTableRow title="MAC:">{mac}</DetailsTableRow>

                    <LocationDetail slug={location} />

                    {pool && (
                        <DetailsTableRow title="Server class:">
                            <div className="flex">
                                <InfoTooltip text={pool.description}>{pool.name}</InfoTooltip>
                            </div>
                        </DetailsTableRow>
                    )}

                    <DetailsTableRow title="Created:">
                        <LongDate date={created_at} />
                    </DetailsTableRow>
                    <DetailsTableRow />
                    <DetailsTableBillingAccount
                        valueKey={billing_account}
                        onChange={async (item) => await changeBillingAccount(item.account)}
                    />

                    <MonthlyCostRow price={price} />
                </DetailsTable>
            </DetailsHolder>
        </ContentPane>
    );
}

function VmPublicIp({ item }: { item: VirtualMachineWithAssigned }) {
    const { location, private_ipv4, assignedPublicIp } = item;
    const unAssignIpMutation = useStandardMutation(ipQuery.unassignIpAddressMutation);
    const assignIpAddressMutation = useStandardMutation(ipQuery.assignIpAddressMutation);

    async function assignIpAddress(address: AssignedIpAddress | null) {
        if (address) {
            await assignIpAddressMutation.mutateAsync({
                location,
                address: address.address,
                body: { private_ip: private_ipv4 },
            });
        } else if (assignedPublicIp) {
            await unAssignIpMutation.mutateAsync({ location, address: assignedPublicIp.address });
        }
    }

    return (
        <DetailsTableIpAddress
            value={assignedPublicIp}
            location={location}
            onChange={async (obj) => await assignIpAddress(obj)}
        />
    );
}

function MetricsContent({ vm }: { vm: VirtualMachineLoc }) {
    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Metrics</h2>
            <MetricsGrid vm={vm} />
        </ContentPane>
    );
}

function sortByCreatedAtDesc<T extends { created_at: string }>(items: T[]) {
    return sortBy(items, [(item) => apiDatetimeToDate(item.created_at).getTime(), "desc"]);
}

function DisksContent({ vm }: { vm: VirtualMachineWithAssigned }) {
    // NB: Not sorted by created_at, because the boot disk should be shown as the first one
    const { storage } = vm;

    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Disks</h2>

            <WTable
                afterTable={
                    <WModal
                        button={
                            // TODO: Create a common component for "table footer" type buttons
                            <WButton
                                color="primary"
                                size="bar"
                                variant="border"
                                icon="jp-stack-icon"
                                action={undefined}
                            >
                                Add Additional Disk
                            </WButton>
                        }
                    >
                        <DiskAddOrResizeModal vm={vm} disk={null} />
                    </WModal>
                }
            >
                <thead>
                    <tr>
                        <th>UUID</th>
                        <th>Size (GB)</th>
                        <th>Created</th>
                        <th>Est. Monthly Cost</th>
                        <th>Mode</th>
                        <th>Type</th>
                        <th />
                    </tr>
                </thead>
                <WTableBody>
                    {storage.map((item) => (
                        <tr key={item.uuid}>
                            <td>
                                <ClipBoardTooltip>{item.uuid}</ClipBoardTooltip>
                            </td>
                            <td>{item.size}</td>
                            <td>
                                <LongDate date={item.created_at} />
                            </td>
                            <td>
                                <CurrencyMonthly value={vm.priceCalc.getDiskPrice(item).hourly} />
                            </td>
                            <td>{item.primary ? "Boot" : "-"}</td>
                            <td>SSD disk</td>
                            <td className="text-right">
                                <div className="flex gap-0.5em justify-end">
                                    <WModal button={<WModalButton label="Resize" inTable icon="jp-expand-2-icon" />}>
                                        <DiskAddOrResizeModal vm={vm} disk={item} />
                                    </WModal>
                                    <DiskDeleteModal vm={vm} disk={item} />
                                </div>
                            </td>
                        </tr>
                    ))}
                </WTableBody>
            </WTable>
        </ContentPane>
    );
}

class PrimaryNetworkInterfaceNotFound extends Error {
    constructor(uuid: string) {
        super(`Primary network interface not found for VM ${uuid}, has the VM been deleted?`);
    }
}

function NetworkingContent({ vm }: { vm: VirtualMachineWithAssigned }) {
    const { uuid, assignedPublicIp, location } = vm;
    const interfaces: VmInterface[] = useSuspenseQueryAtom(allInterfacesQueryAtom(location, uuid));

    const vmi = first(interfaces);
    if (!vmi) {
        throw new PrimaryNetworkInterfaceNotFound(uuid);
    }

    const publicIPv6Address = vmi.public_ipv6_address?.address;
    const privateIPv4Address = !vmi.private_ip_address.is_ipv6 ? vmi.private_ip_address.address : undefined;

    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Network Interfaces</h2>
            <p className="color-muted pb-1">This virtual machine is accessible by:</p>
            <ul className="color-muted text-muted m-0 p-0 pl-4 pb-4">
                <li>resources in the same VPC network using private addresses of primary interfaces</li>
                <li>resources in the VPCs shared by VLAN sub-interfaces using their respective private address</li>
            </ul>

            <WTable
                afterTable={
                    <AddSubInterfaceButton
                        uuid={uuid}
                        vmiUuid={vmi.vmi_uuid}
                        networkUuid={vmi.network.uuid}
                        location={location}
                        button={
                            <WButton color="primary" size="bar" variant="border" icon="jp-icon-add" action={undefined}>
                                Add Sub-Interface
                            </WButton>
                        }
                    />
                }
            >
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>VLAN ID</th>
                        <th>Private IPv4</th>
                        <th>Public IPv4</th>
                        <th>Public IPv6</th>
                        <th>MAC</th>
                        <th>VPC Name</th>
                        <th />
                    </tr>
                </thead>
                <WTableBody>
                    <tr>
                        <td className="whitespace-nowrap">
                            <MaskIcon className="color-primary jp-icon-caretdown size-0.875rem mr-1" />
                            Primary
                        </td>
                        <td>-</td>
                        <td>
                            {privateIPv4Address ? (
                                <ClipBoardTooltip text={privateIPv4Address}>
                                    <span className="color-primary">{privateIPv4Address}</span>/
                                    {vmi.network.subnet.split("/")[1]}
                                </ClipBoardTooltip>
                            ) : (
                                "-"
                            )}
                        </td>
                        <td>
                            {assignedPublicIp?.address ? (
                                <ClipBoardTooltip>{assignedPublicIp?.address}</ClipBoardTooltip>
                            ) : (
                                "-"
                            )}
                        </td>
                        <td>{publicIPv6Address ? <ClipBoardTooltip>{publicIPv6Address}</ClipBoardTooltip> : "-"}</td>
                        <td>{vmi.vmi_mac}</td>
                        <td>
                            <ResourceWithIcon type="vpc" title={vmi.network.name} />
                        </td>
                        <td>
                            <WTooltip text="Add Sub-Interface">
                                <AddSubInterfaceButton
                                    uuid={uuid}
                                    vmiUuid={vmi.vmi_uuid}
                                    networkUuid={vmi.network.uuid}
                                    location={location}
                                    button={
                                        <WButton
                                            color="primary"
                                            size="xs"
                                            variant="ghost"
                                            icon="jp-icon-add"
                                            action={undefined}
                                        />
                                    }
                                />
                            </WTooltip>
                        </td>
                    </tr>

                    {vmi.subinterfaces.map((item) => {
                        const _privateIPv4Address =
                            item.ip_address.type === "private" && !item.ip_address.is_ipv6
                                ? item.ip_address.address
                                : undefined;

                        return (
                            <tr key={item.id}>
                                <td className="whitespace-nowrap" style={{ paddingLeft: "26px" }}>
                                    <WTooltip text="View config commands">
                                        <SubInterfaceModal
                                            vlanTag={item.vlan_tag}
                                            macAddr={item.mac_addr}
                                            ipAddr={_privateIPv4Address ?? ""}
                                        />
                                    </WTooltip>
                                </td>
                                <td>{item.vlan_tag}</td>
                                <td>
                                    {_privateIPv4Address ? (
                                        <ClipBoardTooltip text={_privateIPv4Address}>
                                            <span className="color-primary">{_privateIPv4Address}</span>/
                                            {item.network.subnet.split("/")[1]}
                                        </ClipBoardTooltip>
                                    ) : (
                                        "-"
                                    )}
                                </td>
                                <td>-</td>
                                <td>-</td>
                                <td>{item.mac_addr}</td>
                                <td>
                                    <ResourceWithIcon type="vpc" title={item.network.name} />
                                </td>
                                <td>
                                    <SubInterfaceDeleteModal uuid={vm.uuid} ifUuid={item.uuid} location={location} />
                                </td>
                            </tr>
                        );
                    })}
                </WTableBody>
            </WTable>
        </ContentPane>
    );
}

function BackupsContent({ vm, vpc }: { vm: VirtualMachineWithAssigned; vpc?: VpcWithType }) {
    //#region Hooks
    const { backupTime } = useConfig();
    const navigate = useNavigate();
    const toggleBackupsMutation = useStandardMutation(enableDisableBackupMutation);
    //#endregion

    const items = sortByCreatedAtDesc(getReplicasByType(vm, "backup"));
    const latestItem = first(items);

    const { backup: backupsEnabled } = vm;
    const price = vm.priceCalc.prices.backupsPrice;

    async function toggleBackups(vm: VirtualMachineLoc) {
        const { location, uuid } = vm;
        await toggleBackupsMutation.mutateAsync({ location, body: { uuid } });
    }

    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Backups</h2>

            {backupsEnabled ? (
                <p className="color-muted pb-4">
                    Automatic system-level backups are enabled. Use the backup images to restore your virtual machine or
                    create a new virtual machine from the backup with modified size parameters. Backups will be stored
                    for a one month period, meaning you will have 4 latest backups.
                </p>
            ) : (
                <>
                    <p className="color-muted pb-4">
                        When you enable the backup service, an automatic disk-level backup will be created from the boot
                        disk (excluding additional disks). A backup will be created once a week and will be listed here.
                        You can later use the backup images to restore your virtual machine or create a new virtual
                        machine with modified size parameters from the backup.
                    </p>
                    <p className="color-muted pb-4">
                        Each backup will be kept for 4 weeks, meaning you will have backups for a one month period.
                    </p>
                    <VmEstimatedReplicaPriceModal vm={vm} type="backup" />
                </>
            )}

            <DetailsTable>
                {/* TODO: vb peaks olema backupide tegemise aeg confitav */}
                {backupsEnabled && (
                    <DetailsTableRow title="Frequency:">
                        {backupTime ? backupTime : "Once a week (Wednesday at 02:00 UTC)"}
                    </DetailsTableRow>
                )}
                {(backupsEnabled || price.hourly > 0) && (
                    <DetailsTableRow title="Est. monthly cost:">
                        <CurrencyMonthly value={price.hourly} />
                    </DetailsTableRow>
                )}
                {!!latestItem && (
                    <DetailsTableRow title="Latest backup:">
                        <LongDate date={latestItem.created_at} />
                    </DetailsTableRow>
                )}
            </DetailsTable>

            {backupsEnabled && (
                <WTable className="mt-4">
                    <thead>
                        <tr>
                            <th>UUID</th>
                            <th>Size (GB)</th>
                            <th>Created</th>
                            <th />
                        </tr>
                    </thead>
                    <WTableBody>
                        {items.map((item) => (
                            <tr key={item.uuid}>
                                <td>
                                    <ClipBoardTooltip>{item.uuid}</ClipBoardTooltip>
                                </td>
                                <td>{item.size}</td>
                                <td>
                                    <LongDate date={item.created_at} />
                                </td>
                                <td className="text-right">
                                    <div className="flex gap-0.5em justify-end">
                                        <WToolButton
                                            icon="jp-icon-add"
                                            label="Create New VM"
                                            inTable
                                            action={async () => await navigate(createVmFromReplicaLink(vm, item, vpc))}
                                        />

                                        <VmRestoreFromReplicaModal vm={vm} replica={item} inTable />
                                        <VmDeleteReplicaModal vm={vm} replica={item} />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </WTableBody>
                </WTable>
            )}

            {backupsEnabled ? (
                <WModal button={<WModalButton label="Disable Backups" icon="jp-wrong-icon" variant="border" />}>
                    <WModalContent title="Disable Backups" label="Disable" modalAction={() => toggleBackups(vm)}>
                        By disabling backups there will not be any further automatic backups made from your virtual
                        machine. Backups done by this point will be kept as is and you can still use them. You can
                        enable backups again whenever needed.
                    </WModalContent>
                </WModal>
            ) : (
                <WButton
                    color="primary"
                    size="bar"
                    variant="border"
                    icon="jp-icon-checkmark"
                    action={() => toggleBackups(vm)}
                >
                    Enable Backups
                </WButton>
            )}
        </ContentPane>
    );
}

function createVmFromReplicaLink(
    vm: VirtualMachineWithAssigned,
    replica: VirtualMachineStorageReplica,
    vpc: VpcWithType | undefined,
) {
    const dummyPassword = "ThisPa$$wordDoesntWorkAnyway123!";
    return link({
        to: "/compute/create",
        state: {
            vmCreateInputs: {
                source_uuid: vm.uuid,
                source_replica: replica.uuid,

                os: {
                    os_name: vm.os_name,
                    os_version: vm.os_version,
                },
                location: vm.location,
                size: {
                    vcpu: vm.vcpu,
                    ram: vm.memory,
                    disks: replica.size,
                    isCustom: false,
                },
                typeId: "virtual_machine",
                reserve_public_ip: !!vm.assignedPublicIp,
                network_uuid: vpc?.uuid,
                designated_pool_uuid: vm.designated_pool_uuid,
                username: vm.username,
                name: `${vm.name}-from-${replica.type}`,
                password: dummyPassword,
                password_confirm: dummyPassword,
            },
        },
    });
}

function SnapshotsContent({ vm, vpc }: { vm: VirtualMachineWithAssigned; vpc?: VpcWithType }) {
    //#region Hooks
    const createMutation = useStandardMutation(createSnapshotMutation);
    const navigate = useNavigate();
    //#endregion

    const items = sortByCreatedAtDesc(getReplicasByType(vm, "snapshot"));
    const latestItem = first(items);

    const price = vm.priceCalc.prices.snapshotsPrice;

    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Snapshots</h2>
            <p className="color-muted pb-4">
                Take a snapshot to create an instant image of your virtual machine's current state. You can later use
                the snapshot to restore your virtual machine state or create a new virtual machine from the snapshot
                with modified size parameters.
            </p>
            <p className="color-muted pb-4">
                For the snapshots you will only pay for the storage used by the snapshot.
            </p>

            {items.length ? (
                <>
                    <DetailsTable>
                        <DetailsTableRow title="Est. monthly cost:">
                            <CurrencyMonthly value={price.hourly} />
                        </DetailsTableRow>
                        <DetailsTableRow title="Latest snapshot:">
                            <LongDate date={latestItem!.created_at} />
                        </DetailsTableRow>
                    </DetailsTable>

                    <WTable className="mt-4">
                        <thead>
                            <tr>
                                <th>UUID</th>
                                <th>Size (GB)</th>
                                <th>Created</th>
                                <th />
                            </tr>
                        </thead>
                        <WTableBody>
                            {items.map((item) => (
                                <tr key={item.uuid}>
                                    <td>
                                        <ClipBoardTooltip>{item.uuid}</ClipBoardTooltip>
                                    </td>
                                    <td>{item.size}</td>
                                    <td>
                                        <LongDate date={item.created_at} />
                                    </td>
                                    <td className="text-right">
                                        <div className="flex gap-0.5em justify-end">
                                            <WToolButton
                                                icon="jp-icon-add"
                                                label="Create New VM"
                                                inTable
                                                action={async () =>
                                                    await navigate(createVmFromReplicaLink(vm, item, vpc))
                                                }
                                            />
                                            <VmRestoreFromReplicaModal vm={vm} replica={item} inTable />
                                            <VmDeleteReplicaModal vm={vm} replica={item} />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </WTableBody>
                    </WTable>
                </>
            ) : (
                <VmEstimatedReplicaPriceModal vm={vm} type="snapshot" />
            )}

            <WButton
                color="primary"
                size="bar"
                variant="border"
                icon="jp-camera-icon"
                action={async () => {
                    await createMutation.mutateAsync({ location: vm.location, body: { uuid: vm.uuid } });
                }}
            >
                Take Snapshot
            </WButton>
        </ContentPane>
    );
}
