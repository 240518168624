import { WModalButton } from "../../components/button/WToolButton.tsx";
import { EditableButton } from "../../components/forms/EditableButton.tsx";
import { FormField } from "../../components/forms/FormField.tsx";
import { FormMaxWidthWrapper } from "../../components/forms/FormMaxWidthWrapper.tsx";
import { RadioGroupDiv } from "../../components/forms/RadioGroupDiv.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import type { VirtualMachineWithAssigned } from "./joinAssignedQuery.ts";
import { MachineSizeInfo } from "./MachineSizeInfo.tsx";
import { VmStopModalHeaderBlock } from "./ModalHeaderBlock.tsx";
import { extractOsFields } from "./os/os.ts";
import { useSizeSelect } from "./useSizeSelect.ts";
import { getPrimaryStorage } from "./vmEntityUtils.ts";
import { resizeVmMutation } from "./vmQuery.ts";
import { VmSizeSelect } from "./VmSizeSelect.tsx";

export function VmResizeToolbarButton(props: VmResizeModalProps) {
    return (
        <WModal button={<WModalButton label="Resize" icon="jp-expand-2-icon" />}>
            <VmResizeModalContent {...props} />
        </WModal>
    );
}

export function VmResizeInfoButton(props: VmResizeModalProps) {
    return (
        <WModal
            button={
                <EditableButton action={undefined}>
                    <MachineSizeInfo vm={props.vm} />
                </EditableButton>
            }
        >
            <VmResizeModalContent {...props} />
        </WModal>
    );
}

export interface VmResizeModalProps {
    vm: VirtualMachineWithAssigned;
}

function VmResizeModalContent({ vm }: VmResizeModalProps) {
    const { vcpu, memory, status, location, uuid } = vm;
    const primary = getPrimaryStorage(vm);

    //#region Hooks
    const mutation = useStandardMutation(resizeVmMutation);

    const primarySize = primary?.size ?? 0;
    const { size, props } = useSizeSelect(
        {
            disks: primarySize,
            vcpu,
            ram: memory,
            isCustom: true,
        },
        extractOsFields(vm),
    );
    //#endregion

    async function resizeVm() {
        await mutation.mutateAsync({
            vm,
            body: {
                vm: {
                    uuid,
                    ram: size.ram,
                    vcpu: size.vcpu,
                },
                disk: primary
                    ? {
                          uuid,
                          size_gb: size.disks,
                          disk_uuid: primary.uuid,
                      }
                    : undefined,
            },
            location,
        });
    }

    return (
        <WModalContent
            title="Resize Virtual Machine"
            label="Resize"
            isActionDisabled={status === "running"}
            modalAction={resizeVm}
            footerNotice={status === "running" ? "Resize is possible only when machine is stopped." : undefined}
        >
            <VmStopModalHeaderBlock vm={vm} showStopButton />

            <FormMaxWidthWrapper>
                <FormField wide label="Size" block isRequired>
                    <RadioGroupDiv>
                        <VmSizeSelect vmData={vm} {...props} />
                    </RadioGroupDiv>
                </FormField>
            </FormMaxWidthWrapper>

            <p>
                Disk size can only be increased. This action is not reversible. Minimum disk size:{" "}
                <b>{primarySize} GB</b>.
            </p>
        </WModalContent>
    );
}
